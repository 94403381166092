<div
    class="m-page--fluid m-content--skin-light2 m-header--fixed m-footer--push"
>
    <div class="m-grid m-grid--hor m-grid--root m-page">
        <!-- BEGIN: Header -->
        <header-v2></header-v2>
        <!-- END: Header -->
        <!-- begin::Body -->
        <div
            class="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body"
        >
            <!-- begin: Body -->

            <modal-message-widget
                v-if="showMessageModal"
                :message="$t('widget.modal.message.security.message')"
                @toggle-message-modal="toggleOpenMessageModal"
            ></modal-message-widget>

            <modal-two-factor-authentication-widget
                v-if="showTwoFactorAuthenticationModal"
                @toggle-two-factor-authentication-modal="toggleOpenTwoFactorAuthenticationModal"
                @toggle-cancel-two-factor-authentication-modal="toggleOpenTwoFactorAuthenticationModal"
            ></modal-two-factor-authentication-widget>

            <div
                class="m-grid__item m-grid__item--fluid m-wrapper wallpaper-wrapper"
                :style="widgetsBackground"
            >
                <agent-view-widget />
                <call-to-action-banner-widget
                    v-if="showCallToActionBannerWidget"
                />
                <div v-if="!noContractsAvailable" class="container main-wrap">
                    <div class="m-content">
                        <div class="row">
                            <div
                                :class="['col-12', contractsDataLength > 1 ? 'col-md-6' : '']"
                            >
                                <welcome-widget></welcome-widget>
                            </div>
                            <div
                                class="col-12 col-md-6 d-flex mb-4 mb-md-0"
                                v-if="contractsDataLength > 1"
                            >
                                <v-select
                                    :options="contractsData"
                                    @input="contractSelected"
                                    label="contractId"
                                    :getOptionLabel="contractLabel"
                                    class="border w-100 my-auto"
                                    :value="currentContractId"
                                    :clearable="false"
                                    :searchable="false"
                                >
                                </v-select>
                            </div>
                        </div>
                        <div>
                            <status-bar-widget
                                v-if="showStatusBarWidget"
                            ></status-bar-widget>
                            <customer-data-correction-widget
                                v-if="showCustomerDataCorrectionWidget"
                                :dataCorrectionRequiredResponse="dataCorrectionRequiredResponse"
                                @dataCorrectionDone="dataCorrectionDone"
                            ></customer-data-correction-widget>
                            <!-- ROW -->
                            <div class="row">
                                <div
                                    class="col-12 col-sm-6 row-items"
                                    v-if="showTariffWidget"
                                >
                                    <tariff-widget
                                        @toggleWidget="toggleGroup1"
                                    ></tariff-widget>
                                </div>
                                <div
                                    class="col-12 col-sm-6 row-items"
                                    v-if="showDepositWidget"
                                >
                                    <deposit-widget
                                        @toggleWidget="toggleGroup1"
                                    ></deposit-widget>
                                </div>
                            </div>

                            <!-- ROW -->
                            <div
                                class="row mobile-modal"
                                v-if="showTariffDetailsWidgetEts && isNonCommodityContract"
                            >
                                <non-commodity-tariff-details-widget
                                    @toggleWidget="toggleGroup1"
                                    @closeWidget="closeGroup1"
                                ></non-commodity-tariff-details-widget>
                            </div>

                            <div
                                class="row mobile-modal"
                                v-if="showTariffDetailsWidgetEts && !isNonCommodityContract"
                            >
                                <tariff-details-widget
                                    @toggleWidget="toggleGroup4"
                                    @closeWidget="closeGroup1"
                                ></tariff-details-widget>
                            </div>

                            <div
                                class="row mobile-modal"
                                v-if="showGroup4.showCancellationWidget"
                            >
                                <cancellation-widget
                                    @closeWidget="closeGroup4"
                                ></cancellation-widget>
                            </div>

                            <div
                                class="row mobile-modal"
                                v-if="showGroup4.showMoveWidget"
                            >
                                <move-widget
                                    @closeWidget="closeGroup4"
                                ></move-widget>
                            </div>

                            <div
                                class="row mobile-modal"
                                v-if="showTariffChangeWidget"
                            >
                                <tariff-change-widget
                                    @closeWidget="closeGroup4"
                                ></tariff-change-widget>
                            </div>

                            <div
                                class="row mobile-modal"
                                v-if="showGroup4.showTariffOverview"
                            >
                                <tariff-overview-widget
                                    @closeWidget="closeGroup4"
                                ></tariff-overview-widget>
                            </div>
                            <div
                                class="row mobile-modal"
                                v-if="showDepositOverviewWidget"
                            >
                                <div class="row-items w-100">
                                    <deposit-overview-widget></deposit-overview-widget>
                                </div>
                            </div>
                            <!-- ROW -->
                            <div
                                class="row mobile-modal"
                                v-if="showDepositChangeWidget"
                            >
                                <deposit-change-widget
                                    @closeWidget="closeGroup1"
                                    @toggleWidget="toggleGroup1"
                                ></deposit-change-widget>
                            </div>

                            <!-- ROW -->
                            <div
                                class="row mobile-modal"
                                ref="accountTransactionWidget"
                                v-if="showAccountTransactionsWidget"
                            >
                                <account-transactions-widget
                                    @closeWidget="closeGroup1"
                                ></account-transactions-widget>
                            </div>

                            <!-- ROW -->
                            <div class="row">
                                <div
                                    class="col-12 col-sm-6 row-items"
                                    v-if="showPostBoxWidget"
                                >
                                    <post-box-widget></post-box-widget>
                                </div>
                                <div
                                    class="col-12 col-sm-6 row-items"
                                    v-if="showMeterWidget"
                                >
                                    <meter-widget
                                        @toggleWidget="toggleGroup1"
                                        :contractInActiveState="contractInActiveState"
                                    ></meter-widget>
                                </div>
                            </div>

                            <!-- ROW -->
                            <div
                                class="row mobile-modal"
                                v-if="showMeterSubmitWidget"
                            >
                                <meter-submit-widget
                                    @closeWidget="closeGroup1"
                                ></meter-submit-widget>
                            </div>

                            <!-- ROW -->
                            <div class="row">
                                <div
                                    class="col-12 col-sm-6 row-items"
                                    v-if="showChangeWidget"
                                >
                                    <change-widget
                                        :groupWidget="showGroup2"
                                        @toggleWidget="toggleGroup2"
                                    ></change-widget>
                                </div>
                                <div
                                    class="col-12 col-sm-6 row-items"
                                    v-if="showCustomerServiceWidget"
                                >
                                    <customer-service-widget
                                        @toggleWidget="toggleGroup2"
                                    ></customer-service-widget>
                                </div>
                            </div>

                            <!-- ROW -->
                            <div
                                class="row mobile-modal"
                                ref="accountTransactionWidget"
                                v-if="showCustomerServiceFormWidget"
                            >
                                <div
                                    class="col-12 fullWidthArea mobile-modal__inner"
                                >
                                    <customer-service-form-widget
                                        @closeWidget="closeGroup2"
                                    ></customer-service-form-widget>
                                </div>
                            </div>

                            <!-- ROW -->
                            <div
                                class="row mobile-modal"
                                v-if="showChangeBillingDataWidget"
                            >
                                <div class="col-12 row-items">
                                    <change-billing-data-widget
                                        @closeWidget="closeGroup2"
                                    ></change-billing-data-widget>
                                </div>
                            </div>
                            <!-- ROW -->
                            <div
                                class="row mobile-modal"
                                v-if="showChangeCustomerDataWidget"
                            >
                                <div class="col-12 row-items">
                                    <change-customer-data-widget
                                        @closeWidget="closeGroup2"
                                    ></change-customer-data-widget>
                                </div>
                            </div>
                            <!-- ROW -->
                            <div
                                class="row mobile-modal"
                                v-if="showChangePaymentDataWidget && stateHasLoaded"
                            >
                                <div class="col-12 row-items">
                                    <change-payment-data-widget
                                        @closeWidget="closeGroup2"
                                    ></change-payment-data-widget>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-else-if="noContractsAvailable"
                    class="container main-wrap customer-data-change-no-contracts"
                >
                    <welcome-widget></welcome-widget>
                    <div class="row">
                        <div class="col-md-12 col-sm-12 col-xs-12 row-items">
                            <change-customer-data-widget></change-customer-data-widget>
                        </div>
                    </div>
                </div>
                <div
                    v-else
                    class="container main-wrap"
                    style="height: 90vh"
                ></div>
            </div>
            <!-- END: Body -->
        </div>
        <!-- end:: Body -->
        <!-- begin::Footer -->
        <app-footer></app-footer>
        <!-- end::Footer -->
    </div>
    <!-- end:: Page -->
    <!-- begin::Scroll Top -->
    <scroll-top></scroll-top>
    <!-- end::Scroll Top -->
</div>
